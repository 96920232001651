<template>
  <div class="columns">
    <div class="column is-4">
      <div
        v-for="call in calls"
        :key="call._id"
        v-bind:class="{ 'has-background-warning': idCall == call._id }"
        class="box mb-1 p-2"
      >
        <div class="level">
          <div class="item-level">
            <p class="is-size-1 has-text-centered has-text-weight-semibold">
              {{ call.number | formatNumber }} {{ call.priority | formatType }}
            </p>
          </div>
          <div class="item-level">
            <p v-show="call.ticket_window" class="title has-text-grey">
              GUICHÊ {{ call.ticket_window }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column"></div>
    <div class="modal" v-bind:class="{ 'is-active': !terminalIsOpened }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Terminal de Chamada</p>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
        </section>
        <footer class="modal-card-foot">
          <button @click="terminalIsOpened = true" class="button is-success">
            Abrir Terminal
          </button>
        </footer>
      </div>
    </div>

    <audio id="call">
      <source src="./../../assets/call.mp3" type=audio/mpeg>
    </audio>
  </div>
</template>
<script>
import io from "./../../services/io";
export default {
  name: "Call",
  data() {
    return {
      calls: [],
      idCall: "",
      terminalIsOpened: false,
    };
  },
  mounted() {
    const self = this;
    io.on("joined", function (e) {
      console.log(e);
    });

    //faz a chamada de um ticket
    io.on("newTicket", function (newTicket) {
      const oldTicket = self.calls.find((f) => f._id == newTicket._id);
      if (!oldTicket) {
        self.calls.push(newTicket);
      } else {
        oldTicket.ticket_window = newTicket.ticket_window;
      }

      self.idCall = newTicket._id;
      setTimeout(function () {
        self.idCall = "";
      }, 1500);

      document.getElementById("call").play();
    });

    //remove um ticket (porque foi cocuido ou cancelado)
    io.on("delTicket", function (ticketId) {
      self.calls = self.calls.filter((f) => f._id != ticketId);
    });
  },
  filters: {
    formatType: function (v) {
      return v ? "P" : "N";
    },
    formatNumber: function (v) {
      return "000)".substring(0, 4 - v.length) + v;
    },
  },
  created() {
    //const terminalId = this.$route.params.id;
    //this.$http.get(`${process.env.VUE_APP_URI}call`).then((response) => {
    //this.calls = response.data;
    //});
    //this.$store.commit("setToken");
  },
};
</script>
<style scoped>
.box {
  border-radius: 0 !important;
}
</style>
import store from "./../store/index";
import { io } from "./../../node_modules/socket.io-client";

const apiUuid = () => {
    const token = store.getters.token;
    if (!token) return;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64)).apiUuid;
}

const options = {
    //path: "/",
    //force new connection: true,
    //reconnectionAttempts: "Infinity",
    //timeout: 10001,*/
    query: { "room": apiUuid() },
    //transports: ["websocket"]
};

//https://socket.io/docs/v3/client-api/index.html

const _socket = io(`${process.env.VUE_APP_SOCKET}`, options);

const _io = {

    on: function (eventType, cb) {
        return _socket.on(eventType, function (data) {
            cb(data);
        });
    },
    emit: function (eventType, data) {
        _socket.emit(eventType, data);
    }

}

export default _io;